import AvatarUsername from "@common/UsernameAvatar";
import { breakpoints, Text, TokenReward } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LinkThankYou } from "../common";

const ContributionsRow = ({
  contribution,
  community,
  amount,
  date,
  receipt_link,
  processing,
  onCommunityClick,
  username,
  image_url,
  onProfileClick,
  user_id,
  reward_token,
  hide_amounts,
  showCommunity,
  isDesktop,
  isMobile,
  isFromProfile,
  showRewardCommunity,
  fromProfileTablet,
  seasons,
}) => {
  const textSize = isMobile ? "body/small" : "body/medium";
  const season = seasons[0];
  const tokenSize = isFromProfile && !isDesktop ? "26px !important" : undefined;

  return (
    <>
      <Row $isFromProfile={isFromProfile}>
        {!isFromProfile && (
          <DataElement className="avatar">
            {!!community && (
              <AvatarUsername
                color="01Primary700"
                username={username}
                community={community}
                onCommunityClick={onCommunityClick}
                image_url={image_url}
                onProfileClick={onProfileClick}
                user_id={user_id}
                textSize={textSize}
              />
            )}
          </DataElement>
        )}
        <DataElement maxWidth="180px" $isFromProfile={isFromProfile} className="contribution">
          <Text color="01Primary700" bold={!isDesktop} typography={textSize} data-id={community?.id}>
            {contribution}
          </Text>
        </DataElement>

        {showCommunity && (
          <DataElement className="community">
            <Text typography={textSize} pointer color="link" bold data-id={community?.id} onClick={onCommunityClick}>
              {community?.name}
            </Text>
          </DataElement>
        )}
        <DataElement className="order_number">
          {season && <Link to={`/seasons/${season.slug}`}>S{season.order_number}</Link>}
        </DataElement>

        {showRewardCommunity && (
          <>
            {!hide_amounts && (
              <DataElementReward
                $isFromProfile={isFromProfile}
                gapReward={fromProfileTablet && "6px"}
                className="rewards"
              >
                <TokenReward
                  hideText
                  amount={+amount}
                  token={reward_token}
                  color="01Primary700"
                  size={tokenSize}
                />
              </DataElementReward>
            )}
          </>
        )}
        <DateElement $isFromProfile={isFromProfile} className="date">
          <Text color="01Primary700" typography={textSize}>
            {formatDate(date)}
          </Text>
        </DateElement>
        {isDesktop && (
          <DataElement>
            {!!receipt_link && (
              <LinkThankYou target="blank" href={receipt_link}>
                View Thank You Note
              </LinkThankYou>
            )}
            {!!processing && (
              <Text typography="body/medium" textAlign="right" color="01Primary700" size="16px" lineHeight="24px">
                Receipt processing
              </Text>
            )}
          </DataElement>
        )}
      </Row>
      <SpacingRow />
    </>
  );
};

const DataElement = styled.td`
  ${({ pointer }) => pointer && `cursor: pointer;`}
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  @media ${breakpoints.tablet} {
    height: auto;
    min-height: 90px;
  }
`;

const DataElementReward = styled.td`
  ${({ pointer }) => pointer && `cursor: pointer;`}
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  @media ${breakpoints.tablet} {
    height: auto;
    min-height: 90px;
  }

  & > div {
    gap: ${({ gapReward }) => gapReward && gapReward};
  }

  @media ${breakpoints.mobile} {
    ${({ isFromProfile }) =>
      isFromProfile &&
      `
      padding: 0!important;
      position: relative;
      &>div {
        margin-top: auto;
      }
      `}
  }
`;

const DateElement = styled.td`
  ${({ pointer }) => pointer && `cursor: pointer;`}
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  @media ${breakpoints.tablet} {
    height: auto;
    min-height: 90px;
  }
  @media ${breakpoints.mobile} {
    ${({ $isFromProfile }) =>
      $isFromProfile &&
      `
      padding: 0!important;
      position: relative;
      &>div {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      `}
  }
`;

export const Row = styled.tr`
  background: ${({ theme, $isFromProfile }) => ($isFromProfile ? theme.colors["01Primary110"] : theme.colors.surfaces)};
  border-radius: 8px;

  @media ${breakpoints.mobile} {
    display: grid;
    padding: 16px;
    gap: 16px;
    grid-template-areas:
      "a  b "
      "a2 b2";

    td {
      padding: 0;
      height: auto;
    }

    .community,
    .order_number,
    .date {
      text-align: end;
    }

    .avatar {
      grid-area: a;
    }

    .contribution {
      grid-area: a2;
    }

    .community {
      grid-area: b;
    }

    .order_number {
      grid-area: b;
    }

    .date {
      grid-area: b2;
    }
  }
`;

const SpacingRow = styled.tr`
  margin-bottom: 20px;
  display: block;
  @media ${breakpoints.mobile} {
    margin-bottom: 16px;
  }
`;

export default ContributionsRow;
